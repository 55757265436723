import axiosInstance from '../../../helpers/axios';

const getVideosRequest = (query) => {
  return axiosInstance.get(`video/get_by_query${query ? query : ''}`);
};

const getCountVideos = (query) => {
  return axiosInstance.get(`video/count${query ? query : ''}`);
};

const getVideoRequest = (videoId) => {
  return axiosInstance.get(`video/get_by_query?video_id=${videoId}`);
};

const postVideoRequest = (data) => {
  return axiosInstance.post('video/store', data);
};

const patchVideoRequest = ({ form, videoId }) => {
  return axiosInstance.patch(`video/${videoId}`, form);
};

const deleteVideoRequest = (videoId) => {
  return axiosInstance.delete(`video/${videoId}`);
};

const deleteVideosRequest = (videos) => {
  return axiosInstance.post(`video/delete`, videos);
};

const uploadRequest = (data) => {
  return axiosInstance.post('auth/upload', data);
};

export {
  getVideosRequest,
  getVideoRequest,
  postVideoRequest,
  patchVideoRequest,
  deleteVideoRequest,
  uploadRequest,
  getCountVideos,
  deleteVideosRequest,
};
